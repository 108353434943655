import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const DoorWelcomePage = () => (
  <Layout>
    <SEO title="Velkommen inn" />
    <div>
      <h1>Her bor jeg 🏡</h1>
      <p>Hvorfor ringer du ikke bare på? 😜</p>
    </div>
  </Layout>
)

export default DoorWelcomePage
